<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tim.other_training_honorarium_sheet') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                  <b-col lg="6" sm="6">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="circular_memo_no"
                    >
                    <template v-slot:label>
                      {{$t('elearning_iabm.circular_memo_no')}}
                    </template>
                    <b-form-input
                      id="circular_memo_no"
                      v-model.lazy="search.circular_memo_no"
                      :placeholder="$t('elearning_iabm.circular_memo_no')"
                    ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.fiscal_year')}}
                      </template>
                      <v-select name="organization"
                        v-model="search.fiscal_year_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= fiscalYearList
                        :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.organization')"
                        label-for="organization"
                    >
                      <v-select name="organization"
                          v-model="search.org_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= orgList
                          :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_category_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_category')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_category_id"
                          :options="trainingCategoryList"
                          id="training_category_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_title_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_title')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_title_id"
                          :options="trainingTitleList"
                          id="training_title_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" sm="12" class="text-right">
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                  </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tim.other_training_honorarium_sheet') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <router-link to="other-bill-payment-form" :class="'btn btn_add_new font-weight-bold'"><i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}</router-link>
                    </template>
                    <!-- <template v-slot:headerAction>
                      <router-link to="other-trainee-trainer-registration-form" :class="'btn btn-success text-light'">{{  $t('globalTrans.add_new') }}</router-link>
                    </template> -->
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                          <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.save')}}</span>
                                          <span class="badge badge-success" v-else>{{$t('globalTrans.finalSave')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <!-- <b-button variant="btn btn-sm btn-success" v-if="data.item.status == 1" size="sm" @click="finalSave(data.item)" ><i class="far fa-check-square m-0"></i></b-button> -->
                                            <!-- <b-button v-b-modal.modal-4 variant="btn btn-sm btn-info" size="sm" @click="edit(data.item)">
                                              <i class="fas fa-eye"></i>
                                            </b-button> -->
                                            <router-link :to="`other-training-honorarium-sheet-details?circilarMemoNo=${data.item.circular_memo_no}`" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link>
                                            <!-- <router-link v-if="data.item.status == 1" title="Edit" :to="`/training-e-learning-service/tpm/course-module-form?id=${data.item.id}`" class=" btn btn-sm btn-warning"><i class="ri-ball-pen-fill m-0"></i></router-link> -->
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <!-- <b-modal id="modal-4" size="lg" :title="$t('elearning_tpm.other_trainee_trainer_registration') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
            <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal> -->
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentHonorarumList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
// import Details from './Details'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
        return {
          search: {
            circular_memo_no: '',
            org_id: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            fiscal_year_id: 0
          },
          trainingCategoryList: [],
          trainingTitleList: []
        }
    },
    computed: {
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
            //   { label: this.$t('globalTrans.course'), class: 'text-left' },
            //   { label: this.$t('elearning_tpm.course_module'), class: 'text-left' },
            //   { label: this.$t('elearning_tpm.course_module_details'), class: 'text-left' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
              { label: this.$t('elearning_config.organization'), class: 'text-center' },
              { label: this.$t('elearning_config.training_title'), class: 'text-center' },
            //   { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
            //   { key: 'course_name_bn' },
            //   { key: 'course_module_bn' },
            //   { key: 'course_module_details_bn' },
              { key: 'circular_memo_no' },
              { key: 'org_bn' },
              { key: 'training_title_bn' },
            //   { key: 'status' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
            //   { key: 'course_name' },
            //   { key: 'course_module' },
            //   { key: 'course_module_details' },
              { key: 'circular_memo_no' },
              { key: 'org' },
              { key: 'training_title' },
            //   { key: 'status' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
      finalSave (item) {
        // this.toggleStatusCustom2(trainingElearningServiceBaseUrl, courseModuleFinalSave, item)
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      remove (item) {
        //   this.changeStatus(trainingElearningServiceBaseUrl, courseModuleToggleStatus, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, billPaymentHonorarumList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
